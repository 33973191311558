import React, { Component } from 'react';

import Booking from './Booking'

class App extends Component {
  render() {
    return <Booking />
  }
}

export default App;
