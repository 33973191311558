import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/pl'

import Store from './services/StoreStore'
import { registerToken } from './services/auth.js'

import Modal from './components/Modal'

export const getStore = () => Store(`
  {
    tasks: search(type: "b2ccontent", source: "database", filter: "type:meeting") {
      ... on B2CContent {
        id
        indexType
        name
        start_date
        end_date
        content
        assigned
        user_id
      }
    }
    organization {
      users {
        id
        name
      }
    }
  }`)

const Hour = props => {
  return <div
    onClick={!props.isBooked ? props.book : undefined}
    className='rounded-full text-grey-darkest bg-white hover:bg-blue hover:text-white flex items-center hover:text-4xl justify-center cursor-pointer border border-blue-dark'
    style={{ width: '60px', height: '60px', transition: '0.3s', marginBottom: '5px', background: props.isBooked && 'grey' }}>
    <span>{props.hour}</span>
  </div>
}

const Day = props => {
  const bookedHours = props.booked.map(el => moment(el.start_date).format('HH:mm'))

  return <div className='flex flex-col items-center' style={{marginBottom: '10px'}}>
    <div className='flex flex-col text-grey-darker items-center' style={{marginBottom: '10px'}}>
      <div style={{ fontSize: '20px' }}>{props.dayName}</div>
      <div>{props.date.format('DD.MM.YY')}</div>
    </div>
    {props.hours.map((el, i) => {
      const isBooked = bookedHours.includes(el)
      return <Hour isBooked={isBooked} book={() => props.startBooking(props.date, el)} key={i} hour={el} />
    })}
  </div>
}

const Book = props => {
  const userRegistered = window.localStorage.getItem('bookingToken')

  return <div style={{marginBottom: '10px', padding: '30px', width: '300px', height: '300px'}}>
    <div className='text-grey-dark border-b' style={{ fontSize: '24px', marginBottom: '10px' }}>{props.name}</div>
    <div>Termin</div>
    <div style={{ marginBottom: '20px' }}>{props.date.format('LL')} godzina {props.hour}</div>
    {!userRegistered ?
      <form onSubmit={(e) => {
          e.preventDefault()
          props.register()
        }}>
        <div style={{ marginBottom: '10px' }}>
          <label>
            * Imie i nazwisko:
            <input type='text' className='border' value={props.bookName} required name='bookName' onChange={props.registerHandler} />
          </label>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>
            * E-mail:
            <input type='email' className='border' value={props.email} required name='email' onChange={props.registerHandler} />
          </label>
        </div>

          <button type='submit' className='bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded'>
            Zarezerwuj
          </button>
          <button onClick={props.closeModal} type='button' className='bg-red hover:bg-blue-dark text-white font-bold py-2 px-4 rounded' style={{ marginLeft: '5px' }}>
            Anuluj
          </button>
      </form>
      :
      <div>
        <button onClick={props.book} className='bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded'>
          Potwierdź
        </button>
        <button onClick={props.closeModal} className='bg-red hover:bg-blue-dark text-white font-bold py-2 px-4 rounded' style={{ marginLeft: '5px' }}>
          Anuluj
        </button>
      </div>
    }
  </div>
}

const Booking = observer(class _Booking extends React.Component {
  state = {
    name: 'Krzysztof Sikorski',
    hours: ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'],
    currentDay: moment().startOf('day'),
    daysToDisplay: [],
    startedBooking: false
  }

  getBooked = () => {
    const urlParams = new URLSearchParams(window.location.search)
    fetch("https://mega2.storwork.pl/calendar?user_id=" + urlParams.get('user_id'))
    .then(res => res.json())
    .then(data => this.setState({ booked: data }))
  }

  componentDidMount() {
    this.getBooked()
    this.displayDays()
  }

  displayDays = () => {
    let tempDays = []
    for (let i = 0; i < 7; i++) {
      tempDays.push(this.state.currentDay.clone().add(i, 'd'))
    }
    this.setState({ daysToDisplay: tempDays })
  }

  displayNextWeek = () => {
    this.setState({ currentDay: this.state.currentDay.clone().add(7, 'd') }, () => {
      this.displayDays()
    })
  }

  displayPreviousWeek = () => {
    this.setState({ currentDay: this.state.currentDay.clone().subtract(7, 'd') }, () => {
      this.displayDays()
    })
  }

  startBooking = (date, hour) => {
    this.setState({ startedBooking: true, date, hour })
  }

  endBooking = () => {
    this.setState({ startedBooking: false })
  }

  book = () => {
    const urlParams = new URLSearchParams(window.location.search)
    this.setState({startedBooking: false}, () => {
      const hour = parseInt(this.state.hour.split(':')[0])
      const start = date => moment(date).add(hour, 'hours').format('YYYY-MM-DD HH:mm')
      const end = date => moment(date).add(hour + 1, 'hours').format('YYYY-MM-DD HH:mm')
      this.props.store.executeMutation('createb2ccontent', {
        name: `meeting with ${window.localStorage.getItem('userName')}` ,
        start_date: start(this.state.date),
        end_date: end(this.state.date),
        type: 'meeting',
        assigned: ':' + urlParams.get('user_id')
      })
      this.props.store.feedback = id => {
        setTimeout(() => {
          this.getBooked()
        }, 1000)
      }
    })
  }

  render () {
    const date = moment()
    if (!this.state.daysToDisplay[0] || !this.state.booked) return null

    return <div style={{ position: 'relative', maxWidth: '800px' }}>
      <div className='text-grey-darker' style={{ fontSize: '24px' }}>{this.state.name}</div>
      <div
        onClick={() => {
          this.setState({currentDay: moment()}, () => {
            this.displayDays()
          })
        } }
        className='rounded-full text-grey-darkest bg-white hover:bg-blue hover:text-white flex items-center justify-center cursor-pointer border border-blue-dark float-right'
        style={{ width: '60px', height: '60px', transition: '0.3s', marginBottom: '5px' }}>
        <span>TODAY</span>
      </div>
      <div className='flex flexHelper justify-between flex-wrap' style={{padding: '30px', margin: '0 30px'}}>
        {this.state.daysToDisplay.map((el, i) => {
            const valueDates = this.state.booked.filter(book => book.start_date && book.end_date)
            const dates = valueDates.filter(book => moment(book.start_date).format('LL') === el.format('LL'))
            return <Day key={i} booked={dates} startBooking={this.startBooking} dayName={el.format('ddd').toUpperCase()} date={el} hours={this.state.hours} />
          })
        }
      </div>
      <div className='nextPosition'>
        <i style={{ fontSize: '40px' }} className='fal fa-chevron-right text-grey-darker hover:text-grey cursor-pointer' onClick={this.displayNextWeek} />
      </div>
      {date < this.state.daysToDisplay[0] &&
        <div className='backPosition'>
          <i style={{ fontSize: '40px' }} className='fal fa-chevron-left text-grey-darker hover:text-grey cursor-pointer' onClick={this.displayPreviousWeek} />
        </div>
      }
      {this.state.startedBooking &&
        <Modal close={() => this.setState({startedBooking: false})}>
          <Book
            firstBook={this.state.startedBooking}
            closeModal={() => this.setState({startedBooking: false})}
            register={() => this.props.register(this.book, this.endBooking)}
            book={this.book}
            registerHandler={this.props.registerHandler}
            bookName={this.props.bookName}
            email={this.props.email}
            name={this.state.name}
            date={this.state.date}
            hour={this.state.hour} />
        </Modal>
      }
    </div>
  }
})


const Register = class _Register extends React.Component {
  state = {
    email: ''
  }
  register = (book, endBooking) => {
    registerToken(this, book, endBooking)
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }
  render () {
    const userRegistered = window.localStorage.getItem('bookingToken')
    return <Booking store={userRegistered ? getStore() : null} register={this.register} registerHandler={this.handleChange} email={this.state.email} bookName={this.state.bookName} />
  }
}
export default Register
