// NOT FRAMEWORK READY, ADD changable properties
import React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'

const modalRoot = document.getElementById('root')

const OverlayContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
`

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 0.4;
  cursor: pointer;
  display: flex;
  align-itmes: center;
`

const ContentContainer = styled('div')`
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  background: white;
  border-radius: 3px;
  border: 1px solid white;
`

export default class Modal extends React.Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }
  componentDidMount () {
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount () {
    modalRoot.removeChild(this.el)
  }

  subRender () {
    return <OverlayContainer>
      <Overlay onClick={this.props.close} />
      <ContentContainer>
        {this.props.children}
      </ContentContainer>
    </OverlayContainer>
  }

  render () {
    // console.log(window.scrollY);
    return ReactDOM.createPortal(
      this.subRender(),
      this.el
    )
  }
}
