import Store from './Store'

let stores = {}
export default (query, channel = 'default') => {
  if (stores[channel]) {
    if (!query || stores[channel].query === query) return stores[channel]
    stores[channel].query = query
    stores[channel].setQuery(query)
  } else {
    stores[channel] = new Store(query)
  }
  return stores[channel]
}
