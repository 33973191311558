export const registerToken = (that, book, endBooking) => {
  const data = that.state;
  if(data.email) {
    fetch((process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011') + '/graphiql', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        query: `
            query Register($email: String!) {
              registerToken(
                email: $email
              )
            }
          `,
        variables: {
          email: data.email
        }
      })
    })
      .then(response => {
        return response.json()
          .then(data => ({
            ...data,
            status: response.status
          }));
      })
      .then(data =>{
        console.log(data);
        if(data.data.registerToken === 'error') {
          that.setState({
            status:false,
          });
          alert("Registration failed");
        } else {

          const token = data.data.registerToken;
          window.localStorage.setItem('bookingToken', token)
          window.localStorage.setItem('userName', that.state.bookName)
          endBooking()
          that.setState({
            status: true
          }, () => {
            setTimeout(() => book(), 1000)
          })
        }
      })
  }
}
