import client from 'socket.io-client'
import { decorate, observable } from 'mobx'
import jwtDecode from 'jwt-decode'

// const org1key = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoyfSwib3JnYW5pemF0aW9uIjp7ImlkIjoxfSwiaWF0IjoxNTQ3NzA3MzQ3fQ.8Z6yS5zRWWMDC2eGaBA-1ly22iZh_Ygfzvf8662e1r4`
// const org2key = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoyfSwib3JnYW5pemF0aW9uIjp7ImlkIjoyfSwiaWF0IjoxNTQ3NzE0MTkxfQ.rr_C0Ppb3WZey1Ygx4griQTsiJQdVfALM6d72jcwjsU`
// const org3key = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwidXNlciI6eyJpZCI6MTAwfSwib3JnYW5pemF0aW9uIjp7ImlkIjoxfSwiaWF0IjoxNTE2MjM5MDIyfQ.Y3eHfqIC2528MeJxSteIMRUNSC6xEJYUoaOFlsbA9zs`

export default class Store {
  token = null
  socket = null
  data = null
  query = null
  loaded = false
  setQuery = query => {
    this.query = query
    this.socket.emit('newQuery', this.query)
  }
  sync = () => {
    // console.log(this.data)
    this.socket.emit('sync', this.data)
  }
  declareVoiceChannel = channel => {
    this.socket.emit('declareVoiceChannel', channel)
  }
  executeMutation = (mutation, input) => {
    this.socket.emit('executeMutation', {
      mutation, input
    })
  }
  getData = data => {
    // console.log(data)
    this.data = data
    this.loaded = true
  }
  loadMore = () => {
    this.socket.emit('loadMore')
  }
  feedback = (data) => {} // Change to get for example ID
  getUser = () => this.token ? jwtDecode(this.token) : null
  error = err => console.log(err)
  initSocket = (query) => {
    const localToken = window.localStorage.getItem('bookingToken')
    const token = localToken
    this.token = token
    const socket = client(process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:3011', {
      query: {
        authorization: 'Bearer ' + token
      }
    })
    socket.on('connect', () => {
      console.log('Connected')
      this.socket = socket
      this.socket.on('feedback', data => {
        this.feedback(data)
      })
      this.socket.on('disconnect', function(){
        console.log('Disconnected')
      })
      this.socket.on('error', this.error)
      this.socket.on('newDataLoaded', this.getData)
      if (query) {
        console.log('Initial query set')
        this.setQuery(query)
      }
    })
  }
  constructor(query) {
    this.initSocket(query)
  }
}

decorate(Store, {
  data: observable,
  query: observable,
  token: observable,
  loaded: observable
})
